import React, { PureComponent } from "react"
import Layout from '../components/layout';
import Resources from '../components/resources';

class ResourcesPage extends PureComponent {
  render() {
    return (
      <Layout>
        <Resources/>
      </Layout>
    )
  }
}

export default ResourcesPage;
