import React, { PureComponent } from "react"
import { Row } from 'react-bootstrap';

export default class Resources extends PureComponent {
  render() {
    const { defaultContentStyles } = this.props;
    return (
      <Row style={defaultContentStyles} className='resources'>
        <h1>Resources</h1>
        <ul className='resources-list'>
          <li>
            <a href='https://bit.ly/drtonicoaches_apply'>
              Schedule A Free Consultation
            </a>
          </li>
          <li>
            <a href='https://youtu.be/1zlgAIOOvhs'>
              Life Mastery TV: Discussing How to Navigate Resentment
            </a>
          </li>
          <li>
            <a href='https://argenalinstitute.com/91'>
              The Argenal Institute, Working Parents Podcast: When Selfishness Gets In The Way of Self-Care
            </a>
          </li>
          <li>
            <a href='https://jenniferwhitacre.com/coach-or-therapist-how-to-choose-the-service-that-best-fits-your-needs-with-dr-toni-warner'>
              The Yes, And… Podcast with Jennifer Whitacre: Deciding Between Therapy or Coaching?
            </a>
          </li>
          <li>
            <a href='https://youtu.be/q2LU-4yWNWQ'>
              Brave Healer Publications: Managing Stress Naturally
            </a>
          </li>
          <li>
            <a href='https://www.youtube.com/watch?v=vj-_qylizuc'>
              The Wellness Universe, Co-Authored Chapter Reading: De-Clutter Your Mind, Reduce Burnout & Boost Productivity
            </a>
          </li>
          <li>
            <a href='https://courses.boldandbalancedcoaching.com/products/courses/view/31'>
              Your Free Stress Less Checklist
            </a>
          </li>
          <li>
            <a href='https://courses.boldandbalancedcoaching.com/products/courses/view/6'>
              Your Free Clarity Assessment
            </a>
          </li>
          <li>
            <div>
              Guest Speaker for the Uncommen Womens Podcast. The Power OF Redefining You With Dr. Toni Warner
            </div>
            <iframe title="The Power OF Redefining You With Dr. Toni Warner" allowtransparency="true" height="315" width="560" frameborder="0" scrolling="no" data-name="pb-iframe-player" src="https://www.podbean.com/player-v2/?from=embed&i=yhhhn-10f0c6b-pb&share=1&download=1&skin=f6f6f6&btn-skin=8bbb4e&size=315" allowfullscreen/>
          </li>
          <li>
            <div>
              Guest Speaker for the Moshi Meditation App: Positive Psychology & Parenting
            </div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/3RxS6h2e61k" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </li>
          <li>
            <div>
              “Inspiration and Motivation”
            </div>
            <iframe width="560" height="315" src="https://www.youtube.com/embed/4B_N1eWE79M" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          </li>
        </ul>
      </Row>
    )
  }
}
